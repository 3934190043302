.BlogPage {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: auto;
    padding-top: 0px;
    padding-bottom: 50px;
    position: relative;

    .BlogPageHeader {
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: end;
        padding: 50px 5% 50px 5%;
        width: 100%;
        height: auto;
        overflow: hidden;
        position: relative;
        background-color: #2a1768;

        .BlogPageHeaderTopTop {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: end;
            margin-bottom: 30px;
            width: 100%;
            // z-index: 3;
            img {
                transform: rotate(0deg);
                width: 30px;
                border-radius: 50%;
                background-color: rgba(146, 146, 146, 0.174);
                filter: invert(1);
                cursor: pointer;
            }
            h1 {
                font-size: 30px;
                font-weight: 600;
                color: #ffffff;
                margin-top: 40px;
                width: 100%;
            }
        }

        .BlogPageHeaderTopCategory {
            display: flex;
            justify-content: start;
            width: 22.5%;
            margin-top: 100px;
            padding: 0px 0px 0px 0px;
            a {
                font-size: 20px;
                font-weight: 400;
                color: #ffffff;
            }
            a:hover {
                text-decoration: underline;
            }
        }
    }
    .ArticleMainArticle {
        display: flex;
        width: 100%;
        .ArticleAside {
            display: flex;
            flex-direction: column;
            width: 25%;
            padding: 40px 5% 0px 0px;
            position: sticky;
            height: 800px;
            top: 120px;
            p {
                font-size: 18px;
                font-weight: 500;
                color: #000000;
                border-bottom: 1px solid #4b657726;
                padding-bottom: 15px;
            }
            ul {
                margin-top: 20px;
                padding-left: 0px;
                list-style-type: none;
                transform: 1s;
                li {
                    margin-top: 10px;
                    padding-left: 10px;
                    transition: all 0.2s ease;
                    padding: 10px 0px;

                    a {
                        font-size: 15px;
                        font-weight: 400;
                        color: #4f6473;
                        padding: 10px 0px;
                    }
                }
                li:hover {
                    border-left: 2px solid #2a1768;
                    padding-left: 10px;
                }
                .BlogAsideCall {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 17px;
                    font-weight: 500;
                    color: WHITE;
                    cursor: pointer;
                    background-color: #DA291C;
                    border-radius: 3px;
                    cursor: pointer;
                    padding: 10px;
                    padding-left: 20px;
                    margin-right: 0px;
                    width: 250px;
                    margin-top: 20px;
    
                    img {
                        // background-color: white;
                        height: 20px;
                        width: 20px;
                        margin-left: 10px;
                        filter: invert(1);
                    }
                }
                .BlogAsideCall:last-child{
                    background-color: #2a1768;
                }
            }
            .BlogPageHeaderImagefIND {
                width: 100%;
                box-shadow: rgba(100, 100, 111, 0.127) 0px 7px 09px 0px;
                border-radius: 5px;
                margin-top: 30px;
                .BlogPageHeaderImagefINDTop {
                    width: 100%;
                    padding: 14px 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #f3f4f8;
                    border-radius: 5px 5px 0px 0px;

                    img {
                        width: 20px;
                        margin-right: 10px;
                    }
                    h3 {
                        text-align: center;
                        font-size: 15px;
                        font-weight: 400;
                        color: #0f212ece;
                    }
                }
                .BlogPageHeaderImagefINDBotttom {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    flex-direction: column;
                    width: 100%;
                    padding: 20px 20px;
                    gap: 20px;
                    h5 {
                        font-size: 14px;
                        font-weight: 300;
                        color: #0f212e;
                        margin-top: 5px;
                    }
                    .BlogPageHeaderImagefINDBotttomLeft {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        gap: 12px;
                        input {
                            width: 70%;
                            height: 43px;
                            background: none;
                            color: #2f2f2f;
                            outline: none;
                            border: none;
                            border: 2px solid #d4d4d4;
                            border-radius: 5px;
                            padding: 10px;
                            text-align: left;
                            font-size: 15px;
                            font-weight: 300;
                        }
                        input:focus,
                        textarea:focus {
                            border: 2px solid #2a1768;
                        }
                        input:hover,
                        select:hover,
                        textarea:hover {
                            border: 2px solid #9b9b9b;
                        }
                        a {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 30%;
                            padding: 0px 30px;
                            height: 43px;
                            border-radius: 5px;
                            background-color: #2a1768;
                            border: unset;
                            outline: unset;
                            font-size: 17px;
                            font-weight: 400;
                            color: #ffffff;
                            cursor: pointer;
                        }
                        a:hover{
                            background-color: #0047d4;

                        }
                    }
                }
            }
        }
        .ArticleMain {
            width: 75%;
            .BlogPageHeaderImage {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                flex-direction: column;
                margin-top: -80px;
                padding: 0px 5%;
                img {
                    margin-top: 0px;
                    object-fit: cover;
                    width: 100%;
                    max-height: 500px;
                    transform: rotate(0deg);
                    border-radius: 5px;
                }
                span {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 0px;
                    width: 100%;
                    margin-top: 20px;
                    h5 {
                        color: #000000;
                        font-size: 15px;
                        font-weight: 500;
                    }
                    h4 {
                        align-self: flex-start;
                        color: #4f6473;
                        font-size: 14px;
                        font-weight: 500;
                        // margin-top: 20px;
                    }
                }
                .BlogPageHeaderImagePromise {
                    width: 100%;
                    margin-top: 20px;
                    border-radius: 5px;
                    padding: 20px;
                    background-color: #eceef6;
                    .BlogPageHeaderImagePromiseTop {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-bottom: 15px;
                        border-bottom: 1px solid #e0e1e7;
                        .BlogPageHeaderImagePromiseTopLeft {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            img {
                                margin: unset;
                                width: 25px;
                                width: 25px;
                            }
                            h2 {
                                font-size: 15px;
                                font-weight: 600;
                                margin-left: 10px;
                            }
                        }
                        a {
                            font-size: 14px;
                            font-weight: 300;
                            color: #2a1768;
                        }
                    }
                    p {
                        margin-top: 20px;
                        font-size: 14px;
                        font-weight: 300;
                        color: #515260;
                        a {
                            font-size: 14px;
                            font-weight: 300;
                            color: #2a1768;
                        }
                    }
                }
                .BlogPageHeaderTopText {
                    display: flex;
                    flex-direction: column;
                    margin-top: 20px;
                    padding: 20px 0%;
                    h2 {
                        color: #4f6473;
                        font-size: 14px;
                        font-weight: 500;
                        margin-right: 20px;
                    }
                    span {
                        display: flex;
                        flex-direction: column;
                        align-items: start;
                        justify-content: flex-start;
                        width: 100%;
                        h5 {
                            text-align: start;
                            color: #3b4a56;
                            font-size: 25px;
                            font-weight: 500;
                        }
                        h3 {
                            color: #4f6473;
                            font-size: 18px;
                            font-weight: 300;
                            margin-top: 20px;
                        }
                    }
                }
                .BlogPageHeaderImagefIND {
                    width: 100%;
                    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 09px 0px;
                    border-radius: 5px;
                    margin-top: 30px;
                    .BlogPageHeaderImagefINDTop {
                        width: 100%;
                        padding: 20px 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #f3f4f8;
                        border-radius: 5px 5px 0px 0px;

                        img {
                            width: 40px;
                            margin-right: 10px;
                        }
                        h3 {
                            text-align: center;
                            font-size: 20px;
                            font-weight: 500;
                            color: #0f212ece;
                        }
                    }
                    .BlogPageHeaderImagefINDBotttom {
                        display: flex;
                        align-items: flex-end;
                        justify-content: space-between;
                        width: 100%;
                        padding: 30px 20px;
                        gap: 20px;
                        .BlogPageHeaderImagefINDBotttomLeft {
                            display: flex;
                            align-items: start;
                            flex-direction: column;
                            width: 60%;
                            h5 {
                                text-align: center;
                                font-size: 18px;
                                font-weight: 300;
                                color: #0f212e;
                                margin-top: 10px;
                            }
                            input {
                                width: 100%;
                                height: 50px;
                                background: none;
                                color: #2f2f2f;
                                outline: none;
                                border: none;
                                border: 2px solid #d4d4d4;
                                border-radius: 5px;
                                padding: 10px;
                                text-align: left;
                                font-size: 15px;
                                font-weight: 300;
                                margin-top: 20px;
                            }
                            input:focus,
                            textarea:focus {
                                border: 2px solid #2a1768;
                            }
                            input:hover,
                            select:hover,
                            textarea:hover {
                                border: 2px solid #9b9b9b;
                            }
                        }
                        a {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 40%;
                            padding: 0px 30px;
                            height: 50px;
                            border-radius: 5px;
                            background-color: #2a1768;
                            border: unset;
                            outline: unset;
                            font-size: 20px;
                            font-weight: 400;
                            color: #ffffff;
                            cursor: pointer;
                        }
                    }
                }
            }
            .BlogPagesList {
                padding: 30px 5%;
                width: 100%;
                margin-top: 20px;
                .BlogPagesListBox {
                    display: flex;
                    width: 100%;
                    .BlogPagesListBoxRight {
                        width: 95%;
                        position: relative;
                        img{
                            max-height: 300px;
                            object-fit: cover;
                            max-width: 500px;
                            margin-top: 30px;
                            border-radius: 10px;
                        }
                        h3,
                        h4 {
                            font-size: 30px;
                            font-weight: 700;
                            color: #2a1768;
                            margin-top: 20px;
                        }
                        p {
                            margin-top: 20px;
                            align-self: flex-start;
                            text-align: start;
                            font-size: 17px;
                            font-weight: 400;
                            color: #393939;
                            margin-top: 10px;
                        }
                
                        strong {
                            margin-top: 20px;
                            font-size: 20px;
                            align-self: flex-start;
                            text-align: start;
                            font-size: 20px;
                            font-weight: 500;
                            color: #1c1c1c;
                            margin-top: 10px;
                        }
                        a {
                            color: #2a1768;
                            text-decoration: underline;
                        }
                        ol,
                        ul {
                            margin-top: 35px;
                            li,
                            p {
                                margin-bottom: 20px;
                                color: #000000;
                                font-weight: 400;
                                font-size: 18px;
                                margin-left: -16px;
                                margin-top: -25px;
                                padding: 0px 0px 10px 0px;
                            }
                            li::marker {
                                content: "";
                            }
                        }
                        .BlogSeo {
                            h1 {
                                font-size: 20px;
                                font-weight: 500;
                                color: #0f212e;
                                margin-bottom: 20px;
                            }
                            p strong {
                                font-size: 20px;
                                font-weight: 500;
                                color: #0f212e;
                            }
                            p {
                                margin-bottom: 25px;
                                color: #6e6f81;
                                font-size: 17px;
                                font-weight: 400;
                                line-height: 1.8;
                            }
                            a {
                                color: #2a1768;
                                cursor: pointer;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
            .BlogsListFaq {
                display: flex;
                flex-direction: column;
                padding: 0px 5% 60px 5%;
                width: 100%;
                .BlogsListFaqHead {
                    border-bottom: 1px solid #4b657726;
                    padding: 20px 0px;
                    h1 {
                        font-size: 25px;
                        font-weight: 500;
                        color: #000000;
                    }
                }
                .BlogsListFaqList {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    width: 100%;
                    .BlogsListFaqBox {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-bottom: 1px solid #4b657726;
                        padding: 20px 10px;
                        // background-color: #2e73ff19;
                        margin: 5px;

                        h1 {
                            font-size: 18px;
                            font-weight: 400;
                            color: #313131;
                            padding-right: 20px;
                        }
                        img {
                            width: 20px;
                            // transform: rotate(-180deg);
                        }
                    }
                    .BlogsListFaqBox:hover {
                        background-color: #2e73ff19;
                    }
                }
            }
        }
    }
    .BlogPageFooter {
        width: 100%;

        .BlogsListReview {
            display: flex;
            flex-direction: column;
            padding: 40px 5% 70px 5%;
            width: 100%;
            gap: 20px;
            background-color: #eceef6;
            .BlogsListReviewHead {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid #4b657726;
                padding: 20px 0px;
                h1 {
                    font-size: 25px;
                    font-weight: 500;
                    color: #2a1768;
                }
                a {
                    font-size: 17px;
                    font-weight: 400;
                    color: #2a1768;
                }
                a:hover {
                    text-decoration: underline;
                }
            }
            .BlogsListReviewList {
                display: flex;
                justify-content: space-between;
                width: 100%;
                .BlogsListReviewBox {
                    article {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        height: 100%;
                    }
                    display: flex;
                    flex-direction: column;
                    cursor: pointer;
                    text-decoration: unset;
                    width: 30%;
                    background-color: white;
                    margin-top: 10px;
                    border-radius: 3px;
                    // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 09px 0px;
    
                    .BlogsListReviewBoxLeft {
                        width: 100%;
                        max-height: 180px;
                        img {
                            max-height: 180px;
                            width: 100%;
                            object-fit: cover;
                            transition: all 0.5s ease-in-out;
                            border-radius: 5px 5px 0px 0px;
                        }
                    }
                    .BlogsListReviewBoxRight {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        padding: 20px;
                        h1 {
                            font-size: 15px;
                            font-weight: 500;
                            color: #0f212e;
                            width: 100%;
                        }
                        span {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-top: 30px;
                            width: 100%;
                            h5 {
                                color: #2a1768;
                                font-size: 15px;
                                font-weight: 500;
                            }
                            h4 {
                                color: #4f6473;
                                font-size: 13px;
                                font-weight: 300;
                            }
                        }
                    }
                }
                .BlogsListReviewBox:hover {
                    position: relative;
                    .BlogsListReviewBoxRight {
                        h2 {
                            color: #0f212e;
                        }
                        img {
                            transform: scale(1.05);
                        }
                    }
                }
            }
        }
        .BlogApply {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            padding: 0px 5%;
            padding-top: 40px;
            // background-color: rgb(240, 240, 240);
            h1 {
                text-align: center;
                font-size: 30px;
                font-weight: 500;
                color: #0f212ece;
            }
            .BlogApplyBox {
                margin-top: 40px;
                width: 100%;
                background-color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                border-radius: 5px;
                padding: 40px;
                border-bottom: 2px solid #2a1768;
                box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
                img {
                    width: 60px;
                }
                h2 {
                    text-align: center;
                    font-size: 20px;
                    font-weight: 400;
                    color: #0f212ece;
                    margin-top: 10px;
                }
                button {
                    margin-top: 35px;
                    padding: 15px 45px;
                    border-radius: 3px;
                    background-color: #2a1768;
                    border: unset;
                    outline: unset;
                    font-size: 20px;
                    font-weight: 400;
                    color: #ffffff;
                    cursor: pointer;
                }
            }
        }
    }
}

@media (max-width: 690px) {
    .BlogPage {
        padding: 0px 0% 30px 0%;
        .BlogPageHeader {
            flex-direction: column;
            padding: 40px 5% 100px 5%;
            width: 100%;
            .BlogPageHeaderTopTop {
                width: 100%;
                padding: 0px 0%;
                h1 {
                    font-size: 30px;
                    margin-top: 0px;
                }
                img {
                    // width: 1000px;
                }
            }
            .BlogPageHeaderTopCategory {
                margin-top: 40px;
                width: 100%;
            }
        }
        .ArticleMainArticle {
            .ArticleAsidePhone {
                .ArticleAsidePhoneHeader2 {
                    position: fixed;
                    bottom: 0px;
                    left: 0px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    background-color: white;
                    padding: 15px 20px;
                    box-shadow: #91919133 0px 0px 19px 5px;

                    p {
                        font-size: 16px;
                        font-weight: 500;
                        color: #515260;
                    }
                    img {
                        width: 20px;
                        transform: rotate(-90deg);
                    }
                }
                .ArticleAsidePhoneMainMain {
                    background-color: rgb(255, 0, 0);
                    width: 100%;

                    .ArticleAsidePhoneBoxShadow {
                        position: fixed;
                        bottom: 0px;
                        left: 0px;
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                        background-color: rgba(0, 0, 0, 0.238);
                    }
                    .ArticleAsidePhoneMain {
                        position: fixed;
                        bottom: 0px;
                        left: 0px;
                        animation: example2 0.3s ease-out;
                        z-index: 3;
                        width: 100%;
                        background-color: white;
                        padding: 20px;
                        .ArticleAsidePhoneHeader {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;
                            border-bottom: 1px solid #4b657726;
                            padding-bottom: 15px;
                            p {
                                font-size: 16px;
                                font-weight: 500;
                                color: #515260;
                            }
                            img {
                                width: 20px;
                                transform: rotate(90deg);
                            }
                        }
                        ul {
                            list-style-type: none;
                            padding-left: 0px;
                            margin-top: 10px;
                            width: 100%;
                            li {
                                display: flex;
                                margin-top: 0px;
                                padding-left: 10px;
                                transition: all 0.2s ease;
                                padding: 0px 0px;
                                width: 100%;
                                a {
                                    font-size: 15px;
                                    font-weight: 400;
                                    color: #4f6473;
                                    padding: 10px 0px;
                                    min-width: 100%;
                                }
                            }
                        }
                    }
                }
            }
            .ArticleMain {
                width: 100%;
                .BlogPageHeaderImage {
                    .BlogPageHeaderImagefIND {
                        .BlogPageHeaderImagefINDTop {
                            img {
                                width: 30px;
                            }
                            h3 {
                                font-size: 15px;
                            }
                        }
                        .BlogPageHeaderImagefINDBotttom {
                            flex-direction: column;
                            padding: 20px 30px 30px 30px;

                            .BlogPageHeaderImagefINDBotttomLeft {
                                width: 100%;
                                h5 {
                                    text-align: start;
                                    font-size: 14px;
                                }
                            }
                            a {
                                width: 100%;
                            }
                        }
                    }
                    .BlogPageHeaderTopText {
                        span {
                        }
                    }
                }
                .BlogPagesList {
                    .BlogPagesListBox {
                        // flex-direction: column;
                        .BlogPagesListBoxMiddle {
                            width: 10%;
                            margin-top: 5px;
                            h3 {
                                font-size: 24px;
                                font-weight: 500;
                                color: #0f212e;
                                transition: all 0.5s ease-in-out;
                            }
                        }
                        .BlogPagesListBoxRight {
                            width: 90%;
                            position: relative;
                            padding-left: 5px;
                            h1 {
                                font-size: 15px;
                                font-weight: 500;
                                color: #0f212e;
                                margin-top: 15px;
                            }
                            h2 {
                                margin-top: 7px;
                                color: #4f6473;
                                font-size: 13px;
                                font-weight: 300;
                                margin-bottom: 30px;
                            }
                            // img {
                            //     width: 40px;
                            //     filter: invert(1);
                            //     position: absolute;
                            //     bottom: -15px;
                            //     right: -10px;
                            //     transform: rotate(-90deg);
                            // }
                            h4 {
                                font-size: 10px;
                            }
                        }
                    }
                }
                .BlogsListFaq {
                    overflow: auto;
                    gap: 0px;

                    .BlogsListFaqList {
                        .BlogsListFaqBox {
                            margin: 0px;
                        }
                    }
                }
            }
        }
        .BlogPageFooter {
            .ArticleAside {
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 10px 5% 40px 5%;

                p {
                    font-size: 18px;
                    font-weight: 500;
                    color: #000000;
                    border-bottom: 1px solid #4b657726;
                    padding-bottom: 15px;
                }
                ul {
                    margin-top: 20px;
                    padding-left: 0px;
                    list-style-type: none;
                    transform: 1s;
                    li {
                        margin-top: 10px;
                        padding-left: 10px;
                        transition: all 0.2s ease;
                        padding: 10px 0px;

                        a {
                            font-size: 15px;
                            font-weight: 400;
                            color: #4f6473;
                            padding: 10px 0px;
                        }
                    }
                    li:hover {
                        border-left: 2px solid #2a1768;
                        padding-left: 10px;
                    }
                }
            }
            .BlogsListReview {
                // BlogsListReviewHead
                .BlogsListReviewHead {
                    padding-top: 0px;
                }
                .BlogsListReviewList {
                    flex-direction: column;
                    justify-content: start;
                    .BlogsListReviewBox {
                        width: 100%;
                        margin-top: 30px;
                    }
                }
            }
            .BlogApply {
                .BlogApplyBox {
                    width: 100%;
                    padding: 40px 20px;

                    h2 {
                        width: 100%;
                        font-size: 17px;
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 1700px) {
    .BlogPage {
        .BlogPageHeader {
            padding-right: 13%;
            padding-left: 13%;
        }
        .ArticleMainArticle {
            padding: 0px 20%;
            .ArticleAside {
                padding: 40px 0% 0px 2.5%;
            }
            .ArticleMain {
                .BlogPageHeaderImage {
                    padding-right: 0%;
                    padding-left: 0%;
                }
                .BlogPagesList {
                    padding-right: 0%;
                    padding-left: 0%;
                }
                .BlogsListFaq {
                    padding-right: 0%;
                    padding-left: 0%;
                }
            }
        }
        .BlogPageFooter {
            .BlogsListReview {
                padding: 40px 20%;
            }
            .BlogApply {
                padding: 0px 20%;
                padding-top: 40px;
            }
        }
    }
}
@keyframes example2 {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}
