.FaqPage {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: auto;
    padding-bottom: 40px;
    background-color: #eceef6;

    article {
        width: 100%;
    }
    // MainBanner
    .FaqPageMainBanner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        min-width: 100vw;
        background-color: #2a1768;
        padding: 60px 5% 60px 5%;
        h1 {
            font-size: 30px;
            font-weight: 600;
            color: white;
            text-align: center;
        }
        img {
            transform: rotate(0deg);
            width: 30px;
            border-radius: 50%;
            background-color: rgba(146, 146, 146, 0.174);
            filter: invert(1);
            cursor: pointer;
            position: absolute;
            top: calc(50% - 15px);
            right: 5%;
        }
    }
    .FaqPageListBox {
        padding: 70px 5%;
        margin: 70px 5% 0px 5%;
        border-radius: 5px;
        width: calc(100% - 10%);
        cursor: pointer;
        background-color: white;
        a{
            text-decoration: underline;
            color: #2a1768;
        }
        h1 {
            font-size: 1.5rem;
            font-weight: 600;
            color: #151515;
        }
        h2 {
            font-size: 16px;
            font-weight: 300;
            color: #6e6f81;
            margin-top: 15px;
        }
        h3 {
            font-size: 14px;
            font-weight: 400;
            color: #373841;
            margin-top: 5px;
            margin-top: 20px;
            span {
                background-color: #0156ff38;
                padding: 3px 10px;
                border-radius: 3px;
                border-left: 1px solid #2a1768;
                width: auto;
            }
        }
    }
    .FaqPageListBox:hover {
        transform: scale(1.03);
        transition: 0.5s;
    }
    // FaqPageListBoxMenu
    .FaqPageListBoxMenu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0px 5%;
        margin-top: 20px;
        .FaqPageListBoxMenuBox {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            a {
                display: flex;
                align-items: center;
                justify-content: center;
                h3 {
                    font-size: 20px;
                    font-weight: 400;
                    color: #2a1768;
                }
                h3:hover {
                    text-decoration: underline;
                }
                img {
                    width: 20px;
                    margin-top: 2px;
                }
            }
        }
        .FaqPageListBoxMenuBox:first-child {
            a {
                img {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .FaqListReview {
        display: flex;
        flex-direction: column;
        padding: 0px 5% 50px 5%;
        width: 100%;

        .FaqListReviewHeader {
            h6 {
                font-size: 25px;
                font-weight: 500;
                color: #000000;
                margin-top: 50px;
                border-bottom: 1px solid #4b657726;
                padding: 20px 0px;
                width: 100%;
            }
        }
        .FaqListReviewList {
            display: flex;
            gap: 50px;
            margin-top: 20px;

            .FaqListBoxReview {
                padding: 30px;
                border-radius: 3px;
                margin-top: 20px;
                cursor: pointer;
                background-color: white;
                a {
                    text-decoration: unset;
                    width: 100%;
                    height: 100%;
                    background-color: red;
                    h1 {
                        font-size: 1.3rem;
                        font-weight: 600;
                        color: #151515;
                    }
                    h2 {
                        font-size: 14px;
                        font-weight: 300;
                        color: #6e6f81;
                        margin-top: 5px;
                    }
                    h3 {
                        font-size: 14px;
                        font-weight: 300;
                        color: #373841;
                        margin-top: 5px;
                        margin-top: 20px;
                        span {
                            background-color: #0156ff38;
                            padding: 3px 10px;
                            border-radius: 3px;
                            border-left: 2px solid #2a1768;
                            width: auto;
                        }
                    }
                }
            }
            .FaqListBoxReview:hover {
                transform: scale(1.03);
                transition: 0.5s;
            }
        }
    }
    // MainBanner
    .MainBanner {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        flex-direction: row-reverse;
        min-height: 65vh;
        min-height: 600px;
        min-width: 100vw;
        max-height: 65vh;
        background-color: #eceef6;
        background-color: white;
        padding: 100px 5% 0px 5%;
        overflow: hidden;
        .MainBannerLeft {
            display: flex;
            ruby-align: center;
            flex-direction: column;
            width: 50%;
            padding-left: 30px;
            margin-top: -100px;
            h1 {
                font-size: 30px;
                font-weight: 700;
                color: #2a1768;
            }
            h2 {
                font-size: 25px;
                font-weight: 400;
                color: #2a1768;
                margin-top: 15px;
            }
            a {
                align-items: center;
                justify-content: space-between;
                font-size: 32px;
                font-weight: 700;
                color: #da291c;
                cursor: pointer;
                border-radius: 3px;
                cursor: pointer;
                margin-top: 20px;
            }
            a:hover {
                text-decoration: underline;
            }
            a:last-child {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 300px;
                background-color: #2a1768;
                color: white;
                font-size: 25px;
                padding: 18px 0px;
                font-weight: 500;

                img {
                    height: 25px;
                    width: 25px;
                    margin-left: 10px;
                    filter: invert(1);
                }
            }
        }
        .MainBannerRight {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            width: 50%;
            height: 100%;
            position: relative;
            img {
                position: absolute;
                bottom: -250px;
                margin-top: 100px;
                width: 500px;
                z-index: 2;
                border-radius: 0px;
            }
            .MainBannerRightBubble1 {
                // display: none;
                position: absolute;
                right: 290px;
                bottom: -200px;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background-color: #ffffff;
                opacity: 0.8;
                z-index: 3;
                box-shadow: 0px 10px 15px -3px rgba(255, 255, 255, 0.54);
            }
            .MainBannerRightBubble2 {
                // display: none;
                position: absolute;
                right: 50px;
                bottom: -150px;
                width: 90px;
                height: 90px;
                border-radius: 50%;
                opacity: 0.5;
                background-color: #ffffff;
                z-index: 3;
                box-shadow: 0px 10px 15px -3px rgba(255, 255, 255, 0.54);
            }
        }
    }
    .BlogsListReview {
        display: flex;
        flex-direction: column;
        padding: 40px 5% 70px 5%;
        width: 100%;
        gap: 20px;
        background-color: #eceef6;
        .BlogsListReviewHead {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #4b657726;
            padding: 20px 0px;
            h1 {
                font-size: 25px;
                font-weight: 500;
                color: #2a1768;
            }
            a {
                font-size: 17px;
                font-weight: 400;
                color: #2a1768;
            }
            a:hover {
                text-decoration: underline;
            }
        }
        .BlogsListReviewList {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .BlogsListReviewBox {
                article {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 100%;
                }
                display: flex;
                flex-direction: column;
                cursor: pointer;
                text-decoration: unset;
                width: 30%;
                background-color: white;
                margin-top: 10px;
                border-radius: 3px;
                // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 09px 0px;

                .BlogsListReviewBoxLeft {
                    width: 100%;
                    max-height: 180px;
                    img {
                        max-height: 180px;
                        width: 100%;
                        object-fit: cover;
                        transition: all 0.5s ease-in-out;
                        border-radius: 5px 5px 0px 0px;
                    }
                }
                .BlogsListReviewBoxRight {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    padding: 20px;
                    h1 {
                        font-size: 15px;
                        font-weight: 500;
                        color: #0f212e;
                        width: 100%;
                    }
                    span {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 30px;
                        width: 100%;
                        h5 {
                            color: #2a1768;
                            font-size: 15px;
                            font-weight: 500;
                        }
                        h4 {
                            color: #4f6473;
                            font-size: 13px;
                            font-weight: 300;
                        }
                    }
                }
            }
            .BlogsListReviewBox:hover {
                position: relative;
                .BlogsListReviewBoxRight {
                    h2 {
                        color: #0f212e;
                    }
                    img {
                        transform: scale(1.05);
                    }
                }
            }
        }
    }
}
@media (max-width: 850px) {
    .FaqPage {
        padding: 0px 0% 30px 0%;
        // overflow: hidden;
        .FaqPageMainBanner {
            padding: 60px 5% 60px 5%;
            h1 {
                font-size: 27px;
                font-weight: 600;
            }
            img {
                top: calc(20% - 15px);
            }
        }
        .FaqPageListBox {
            margin-top: 40px;
            padding: 50px 5%;
        }
        .FaqListReview {
            display: flex;
            flex-direction: column;
            // padding-bottom: 20px;
            padding: 0px;

            .FaqListReviewHeader {
                padding: 0px 5% 0px 5%;
                h6 {
                    margin-top: 30px;
                }
            }
            .FaqListReviewList {
                display: flex;
                align-items: center;
                overflow: auto;
                gap: 25px;
                padding: 5%;
                padding-top: 0px;
                .FaqListBoxReview {
                    padding: 20px 30px;
                    min-width: 250px;
                    min-height: 180px;
                }
            }
        }
        .MainBanner {
            flex-direction: column;
            padding: 90px 5% 20px 5%;
            // max-height: 100vh;
            height: auto;
            min-height: 600px;

            .MainBannerLeft {
                width: 100%;
                height: 50%;
                margin-top: -180px;
                padding-right: 0px;
                padding-left: 0px;
                h1 {
                    font-size: 30px;
                }
                h2 {
                    font-size: 20px;
                }
                a {
                    // background-color: white;
                }
            }
            .MainBannerRight {
                width: 100%;
                height: 100%;
                margin-top: 100px;
                img {
                    position: absolute;
                    bottom: -250px;
                    // margin-top: 140px;
                    width: 320px;
                    z-index: 2;
                }
                .MainBannerRightBubble1 {
                    right: 190px;
                    bottom: -220px;
                    width: 120px;
                    height: 120px;
                }
                .MainBannerRightBubble2 {
                    right: 50px;
                    bottom: -130px;
                    width: 90px;
                    height: 90px;
                }
            }
        }
        .BlogsListReview {
            .BlogsListReviewList {
                flex-direction: column;
                justify-content: start;
                .BlogsListReviewBox {
                    width: 100%;
                    margin-top: 30px;
                }
            }
        }
    }
}
@media screen and (min-width: 1700px) {
    .FaqPage {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .FaqPageMainBanner {
            img {
                right: 20%;
            }
        }
        .FaqPageListBox {
            width: calc(100% - 40%);
            // width: 50%;
            margin-left: 20%;
        }
        .FaqPageListBoxMenu {
            padding: 0px 20%;
        }
        .FaqListReview {
            width: 100%;
            padding: 0px 20% 50px 20%;

            .FaqListBoxReview {
                // min-width: 33.3%;
                // background-color: red;
            }
        }
        .MainBanner {
            padding-right: 13%;
            padding-left: 13%;
            .MainBannerLeft {
                padding-left: 0px;
            }
            .MainBannerRight {
                // display: flex;
            }
        }
        .BlogsListReview,
        .BlogsListFaq {
            padding-right: 13%;
            padding-left: 13%;
        }
    }
}
